import { post, App_ID } from "./publicHeader";

class Goods {
  /**
   * 
   * @param {分页} page 
   * @param {分类ID} goods_cat_id 
   * @param {搜索} name 
   */
  async getShopGoodsListy(page, limit, goods_cat_id, name, merch_id, store_id) {
    try {
      const res = await post('/goods/user.goods/index', {
        app_id: App_ID,
        page,
        limit: 6,
        goods_cat_id,
        name,
        merch_id,
        store_id,
      })
      return res;
    } catch (err) {
      console.log(err);
    }
  }
  // 获取商品详情
  async getGoodsDetail(id) {
    try {
      const res = await post('/goods/user.goods/detail', {
        app_id: App_ID,
        id
      })
      return res;
    } catch (err) {
      console.log(err);
    }
  }
  // 获取评价
  async getComment(goods_id, page, limit, ) {
    try {
      const res = await post('/shop/user.order/comment_list', {
        app_id: App_ID,
        goods_id: goods_id,
        page: page,
        limit: limit
      })
      return res;
    } catch (err) {
      console.log(err);
    }
  }
  // 添加到购物车
  async addShopCar(goods_id, product_id, num) {
    try {
      const res = await post('/shop/user.cart/add', {
        app_id: App_ID,
        goods_id,
        product_id,
        num,
      })
      return res;
    } catch (err) {
      console.log(err);
    }
  }
  // 购物车列表
  async goodsCarList() {
    try {
      const res = await post('/shop/user.cart/index', {
        app_id: App_ID,
      })
      return res;
    } catch (err) {
      console.log(err);
    }
  }
  // 获取规格
  async getProduct(id) {
    try {
      const res = await post("/goods/user.goods/product", {
        app_id: App_ID,
        id
      })
      return res;
    } catch (error) {
      console.log(error)
    }
  }
  // 删除购物车商品
  async delGoodsCar(idArr) {
    try {
      const res = await post('/shop/user.cart/delcart', {
        app_id: App_ID,
        id: idArr
      })
      return res;
    } catch (err) {
      console.log(err);
    }
  }
  // 修改购物车数量
  async opreateNum(id, num) {
    try {
      const res = await post("/shop/user.cart/opreate", {
        app_id: App_ID,
        id,
        num
      })
      return res;
    } catch (err) {
      console.log(err)
    }
  }
  async editProduct(id, product_id) {
    try {
      const res = await post("shop/user.cart/changeProduct", {
        app_id: App_ID,
        id,
        product_id
      })
      return res;
    } catch (error) {
      console.log(error)
    }
  }
  // 购物车结算
  async clearingPrice(cart_ids) {
    try {
      const res = await post('/shop/user.order/cartBuy', {
        app_id: App_ID,
        cart_ids
      })
      return res;
    } catch (err) {
      console.log(err);
    }
  }
  // 购物车订单创建
  async orderCarCreat(source, payment_code, address_id) {
    try {
      const res = await post('/shop/user.order/cartCreateOrder', {
        app_id: App_ID,
        source,
        payment_code,
        address_id
      })
      return res;
    } catch (err) {
      console.log(err);
    }
  }
  // 三级分类
  async getGoodsTree() {
    try {
      const res = await post('/shop/user.cate/tree', {
        app_id: App_ID,
      })
      return res;
    } catch (err) {
      console.log(err);
    }
  }

  // diy数据
  async getDiyData() {
    try {
      const res = await post("/api/page/group_list", {
        app_id: 308,
      })
      return res;
    } catch (err) {
      console.log(err)
    }
  }

  // 筛选选项 
  async searchGoods(page, goods_cat_id, ) {
    try {
      const res = await post("/goods/user.Goods/search_goods", {
        app_id: App_ID,
        page,
        limit: 6,
        goods_cat_id,
      })
      return res;
    } catch (err) {
      console.log(err)
    }
  }

  // 筛选选项 
  async searchOptions(id) {
    try {
      const res = await post("/goods/user.Goods/cate_list", {
        id
      })
      return res;
    } catch (err) {
      console.log(err)
    }
  }
}

export default new Goods();