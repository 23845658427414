<template>
  <div>
    <Popup v-model="states" round :style="{ height: '50%' }" position="bottom">
      <div class="sku-top" v-if="Object.keys(goodsObj).length != 0">
        <div class="sku-top-left">
          <img :src="goodsObj.image[0].url" alt />
        </div>
        <div class="sku-price">
          <p class="sku-name">{{goodsObj.spes_desc}}</p>
          <p>
            <span>￥</span>
            <span style="font-size:16px">{{goodsObj.price}}</span>
          </p>
          <p>库存{{goodsObj.stock}}件</p>
          <p>已选：{{goodsObj.spes_desc}}</p>
        </div>
      </div>
      <div class="goodsname-con">
        <span
          :class="[ idx == nowGood ? 'active':'', 'goodsname' ]"
          v-for="(item, idx) in buyObj"
          :key="idx"
          @click="setGood(item, idx)"
        >{{item.spes_desc}}</span>
      </div>
      <div class="sku-btn2" @click="addCar">
        <span>确认</span>
      </div>
    </Popup>
  </div>
</template>
<script>
import { Popup } from "vant";
import Goods from "@/api/goods";
export default {
  components: {
    Popup
  },
  props: {
    buyObj: [Object, Array],
    state: {
      type: Boolean,
      default: false
    },
    proId: {
      type: Number
    },
    carId: [Number, String]
  },
  data() {
    return {
      showSku: true,
      goodsObj: {},
      nowGood: 0,
      buynumber: 1,
      typeStatus: false,
      newProId: "",
      states: false
    };
  },
  watch: {
    states(val) {
      console.log(val)
      if (val == true) {
        for (let i = 0; i < this.buyObj.length; i++) {
          const element = this.buyObj[i];
          if (element.id == this.proId) {
            this.goodsObj = element;
            this.nowGood = i;
            console.log(this.goodsObj);
          }
        }
      } else {
        if (val == false) {
          this.$emit("changeS", false);
        }
      }
    }
  },
  methods: {
    // 修改规格
    async addCar() {
      try {
        const res = await Goods.editProduct(this.carId, this.newProId)
        if (res.code == 200) {
          this.states = false;
        }
        console.log(res);
      } catch (error) {
        console.log(error)
      }
    },
    // 设置商品内容
    setGood(item, idx) {
      console.log(item);
      this.goodsObj = item;
      this.nowGood = idx;
      this.newProId = item.id;
    }
  },
  created() {
    this.states = this.state;
  }
};
</script>
<style lang="scss" scoped>
.sku-top {
  width: 100%;
  height: auto;
  display: flex;
  padding: 20px 10px;
  justify-content: space-between;
  .sku-name {
    font-size: 16px;
    font-weight: 500;
  }
  .sku-top-left {
    width: 100px;
    height: 100px;
    background: #ff8000;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .sku-price {
    flex: 1;
    padding-top: 5px;
    padding-left: 10px;
    p:nth-child(2) {
      margin-top: 8px;
      color: #ff8000;
    }
    p:nth-child(3) {
      color: rgb(29, 12, 12);
    }
  }
}
.goodsname-con {
  width: 100%;
  height: auto;
  display: flex;
  padding: 0 16px;
  margin-top: 20px;
  margin-left: -6px;
  justify-content: flex-start;
  .goodsname {
    width: auto;
    display: inline-block;
    height: 28px;
    padding: 5px 8px;
    line-height: 28px;
    text-align: center;
    border-radius: 6px;
    margin-left: 6px;
    background: rgba(244, 244, 244, 1);
  }
  .active {
    background: rgba(254, 245, 240, 1);
    color: #ff8000;
  }
}

.buynum {
  width: 100%;
  padding: 0 20px;
  height: 50px;
  line-height: 50px;
  display: flex;
  position: fixed;
  left: 0;
  bottom: 44px;
  justify-content: space-between;
}
.sku-btn {
  width: 100%;
  height: 44px;
  line-height: 44px;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  div {
    flex: 1;
    font-size: 14px;
    color: #ffffff;
    text-align: center;
    background-color: #ffb101;
  }
  div:nth-child(2) {
    background-color: #ff8000;
  }
}
.sku-btn2 {
  width: 100%;
  height: 44px;
  line-height: 44px;
  display: flex;
  position: fixed;
  bottom: 0;
  color: #ffffff;
  text-align: center;
  font-size: 16px;
  justify-content: center;
  background-color: #ffb101;
}
</style>
