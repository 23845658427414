<template>
  <div class="shopping-car">
    <div class="shopping-title">
      <span></span>
      <span style="margin-left:18px">
        购物车
        <span style="font-size:14px">(10)</span>
      </span>
      <span>编辑</span>
    </div>
    <div class="shopping-list">
      <div class="shopping-item" v-for="(item, idx) in list" :key="idx">
        <div class="shopping-item-top">
          <div class="shopping-item-top-left">
            <Checkbox
              name="a"
              :value="item.store_id"
              v-model="item.check"
              @click="setStore(item.store_id)"
            ></Checkbox>
            <span class="iconfont iconshops"></span>
            <span>{{item.storeinfo.name}}</span>
            <span class="iconfont iconqianjin"></span>
          </div>
          <span class="shopping-item-top-type">领券</span>
        </div>
        <SwipeCell v-for="(child, childIdx) in item.goods" :key="childIdx">
          <div class="shopping-item-main">
            <Checkbox
              :value="child.goods_id"
              v-model="child.check"
              @click="setGoods(item, child.goods_id)"
            ></Checkbox>
            <div class="shopping-item-main-img">
              <img :src="child.image[0]" alt />
            </div>
            <div class="shopping-item-main-right">
              <span>{{ child.name }}</span>
              <span @click="changePro(child.goods_id,child.product_id,child.id)">{{ child.spes_desc }}</span>
              <div class="shopping-item-main-right-price">
                <span>¥{{ child.price }}</span>
                <Stepper @change="setNum" v-model="child.num" :name="child.id" disable-input></Stepper>
              </div>
            </div>
          </div>
          <template slot="right">
            <Button class="big-btn" square type="primary" text="收藏" />
            <Button class="big-btn" square type="danger" text="删除" @click="deleteCar(child)" />
          </template>
        </SwipeCell>
      </div>
    </div>
    <div class="push-order">
      <Checkbox name="a" v-model="checkAll">全选</Checkbox>
      <div class="push-order-pay">
        <div>
          合计:
          <span style="color:#FF8000;font-size:16px">￥{{ priceSum }}</span>
        </div>
        <div @click="goOrder">结算({{ goodsNum }})</div>
      </div>
    </div>
    <changePro :state="changeState" :carId="carId" :buyObj="buyObj" :proId="proId" v-if="changeState" @changeS="changeS"></changePro>
  </div>
</template>
<script>
import changePro from "@components/public/changePro";
import Goods from "@/api/goods";
import { Checkbox, SwipeCell, Button, Stepper } from "vant";
export default {
  components: {
    Checkbox,
    SwipeCell,
    Button,
    Stepper,
    changePro
  },
  watch: {
    // 全选按钮控制
    checkAll: function(val) {
      if (val) {
        if (this.list.length > 0) {
          for (let i in this.list) {
            this.list[i].check = 1;
            if (this.list[i].goods.length > 0) {
              for (let y in this.list[i].goods) {
                this.list[i].goods[y].check = 1;
              }
            }
          }
        }
      } else {
        for (let i in this.list) {
          this.list[i].check = 0;
          console.log(this.goodId);
          if (this.goodId == this.list[i].goods.length) {
            for (let y in this.list[i].goods) {
              this.list[i].goods[y].check = 0;
            }
          } else {
            return;
          }
        }
      }
		},
		changeState(val) {
			if(val == false) {
				this.getList();
			}
		}
  },
  data() {
    return {
      value: 0,
      checked: false,
      list: [],
      checkAll: true,
      storeCheckList: [],
      goodsCheckList: [],
      allCheck: false, // 店铺内商品是否全选
      allGoods: false, // 全部商品是否全选
      priceSum: 0, // 总金额
      goodsNum: 0, // 商品数量
      goodId: 0,
      storeNum: 0,
      changeState: false,
			buyObj: {},
			proId: "",
			carId: "",
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getList();
    }, 100);
  },
  methods: {
		changeS(val) {
			this.changeState = val;
		},
    async changePro(id,proid,carId) {
			console.log(id,proid);
      try {
        const res = await Goods.getProduct(id);
				console.log(res);
				this.carId = carId;
				this.proId = proid;
        this.buyObj = res.data;
				this.changeState = true;
      } catch (error) {
        console.log(error);
      }
    },
    // 删除商品
    async deleteCar(child) {
      try {
        const res = await Goods.delGoodsCar(child.id);
        console.log(res);
        if (res.code == 200) {
          this.getList();
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 商品选择按钮控制
    setGoods(val, id) {
      this.list.forEach(item => {
        if (val.store_id == item.store_id) {
          if (item.goods.length > 0) {
            for (let y in item.goods) {
              if (id == item.goods[y].goods_id) {
                item.goods[y].check = !item.goods[y].check;
                if (item.goods[y].check) {
                  this.goodId++;
                  // console.log('++', this.goodId);
                  if (this.goodId == item.goods.length) {
                    item.check = 1;
                  }
                } else {
                  this.goodId--;
                  // console.log('--', this.goodId);
                  item.check = 0;
                }
              }
            }
          }
        }
      });
      this.serAllCheck();
    },
    // 店铺选择控制
    setStore(id) {
      this.list.forEach(item => {
        if (id == item.store_id) {
          item.check = !item.check;
          if (item.check) {
            this.goodId = item.goods.length;
            for (let i in item.goods) {
              item.goods[i].check = true;
            }
          } else {
            this.goodId = 0;
            for (let i in item.goods) {
              item.goods[i].check = false;
            }
          }
        }
      });
      this.serAllCheck();
    },
    serAllCheck() {
      this.list.forEach(item => {
        if (item.check) {
          this.storeNum++;
          if (
            this.storeNum == this.list.length ||
            this.storeNum > this.list.length
          ) {
            this.storeNum = this.list.length;
            this.checkAll = true;
          }
        } else {
          this.storeNum--;
          if (this.storeNum < 0) {
            this.storeNum = 0;
          }
          console.log("all", this.storeNum);
          this.checkAll = false;
        }
      });
    },
    async getList() {
      try {
        const res = await Goods.goodsCarList();
        console.log(res);
        if (res.code == 200) {
          for (let i in res.data) {
            this.storeCheckList.push(res.data[i].store_id);
            if (res.data[i].goods.length > 0) {
              for (let y in res.data[i].goods) {
                this.goodsCheckList.push(res.data[i].goods[y].goods_id);
              }
            }
          }
          this.list = res.data;
          // 计算价格
          this.countPrice();
        } else {
          if (res.data.length == 0) {
            this.list = res.data;
          }
          throw res.msg;
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 计算结算价格
    countPrice() {
      var allPrice = 0; // 总金额
      var num = 0;
      for (let i in this.list) {
        this.list[i].storeStatus = false;
        for (let y in this.list[i].goods) {
          this.list[i].goods[y].goodsStatus = false;
          // 求加和
          allPrice += this.list[i].goods[y].num * this.list[i].goods[y].price;
          num += this.list[i].goods[y].num;
        }
      }
      this.priceSum = allPrice.toFixed(2);
      this.goodsNum = num;
    },
    async setNum(val, name) {
      try {
        const res = await Goods.opreateNum(name.name, val);
        this.countPrice();
        console.log(res);
      } catch (error) {
        console.log(error);
      }
    },
    // 购物车结算
    async goOrder() {
      var arr = [];
      this.list.forEach(item => {
        if (item.check) {
          for (let i in item.goods) {
            arr.push(item.goods[i].id);
          }
        } else {
          for (let i in item.goods) {
            if (item.goods[i].check) {
              arr.push(item.goods[i].id);
            }
          }
        }
      });
      arr = arr.join(",");
      try {
        if (arr.length > 0) {
          this.$store.commit("setAddressId", "");
          this.$router.push({
            path: "/makeorder",
            query: {
              idArr: arr,
              priceSum: this.priceSum,
              goodsNum: this.goodsNum
            }
          });
        } else {
          throw "暂无商品";
        }
      } catch (err) {
        this.$toast(err);
      }
      // this.$router.push("/makeorder");
    }
  }
};
</script>
<style lang="scss" scoped>
.shopping-car {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  background: #f4f4f4;
  .shopping-title {
    width: 100%;
    height: 45px;
    font-size: 16px;
    line-height: 45px;
    display: flex;
    position: fixed;
    padding: 0 10px;
    color: #333333;
    justify-content: space-between;
    z-index: 100;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 1);
    span:nth-child(3) {
      font-size: 14px;
    }
  }
  .push-order {
    position: fixed;
    width: 100%;
    height: 42.5px;
    display: flex;
    padding: 0 10px;
    background: #ffffff;
    left: 0;
    bottom: 42px;
    align-items: center;
    justify-content: space-between;
    .push-order-pay {
      width: 80%;
      height: 100%;
      align-items: center;
      display: flex;
      justify-content: flex-end;
      div {
        margin-right: 10px;
      }
      div:nth-child(2) {
        width: 80px;
        height: 28px;
        line-height: 28px;
        text-align: center;
        color: #ffffff;
        background: linear-gradient(
          -90deg,
          rgba(255, 128, 0, 1) 0%,
          rgba(255, 177, 1, 1) 100%
        );
        border-radius: 16px;
      }
    }
  }
  .shopping-list {
    width: 100%;
    height: auto;
    margin-top: 12.5px;
    padding: 45px 0 100px 0;
    .shopping-item {
      width: 94%;
      height: auto;
      display: flex;
      padding: 0 10px;
      border-radius: 4px;
      background: #ffffff;
      flex-direction: column;
      margin: 5px auto;
      .shopping-item-top {
        width: 100%;
        height: 36.5px;
        display: flex;
        color: #333333;
        justify-content: space-between;
        align-items: center;
        .shopping-item-top-left {
          width: 86%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          span {
            margin-left: 6px;
          }
        }
        .shopping-item-top-type {
          color: #ff8000;
        }
      }
      .shopping-item-main {
        width: 100%;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .shopping-item-main-img {
          width: 84px;
          height: 84px;
          margin-left: 6px;
          img {
            width: auto;
            height: auto;
            max-width: 100%;
            max-width: 100%;
            border-radius: 6px;
          }
        }
        .shopping-item-main-right {
          width: 220px;
          height: 100%;
          display: flex;
          padding: 10px;
          flex-direction: column;
          justify-content: space-between;
          .shopping-item-main-right-price {
            flex: 1;
            font-size: 14px;
            align-items: center;
            display: flex;
            color: #ff8000;
            justify-content: space-between;
          }
        }
      }
      .big-btn {
        width: 62px;
        height: 100px;
      }
      .big-btn:nth-child(1) {
        border: none;
        background: #ff8000;
      }
    }
  }
}
</style>

<style lang="scss">
.shopping-car {
  .van-stepper__input {
    color: #333333;
  }
}
</style>
